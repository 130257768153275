import React, { useState, Fragment } from "react";
import imagenes3 from "../images/Home/3_/imagenes3";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Carreras() {
  return (
    <>
      <div class="flex flex-wrap">
        <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
          <div className="rounded-3xl h-full bg-morado p-5 flex items-center flex-wrap">
            <div className="w-2/5">
              <center><img className="w-3/4" src={imagenes3.marketing_digital_min} alt="" /></center>
            </div>
            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
              Estrategia<br /><span className="font-black">Marketing</span><br />Digital
            </div>
          </div>
        </div>
        <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
          <div className="rounded-3xl h-full lg:bg-azul bg-morado p-5 flex items-center flex-wrap">
            <div className="w-2/5">
              <center><img className="w-3/4" src={imagenes3.desarrollo_web_min} alt="" /></center>
            </div>
            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
              Desarrollo y<br /><span className="font-extrabold">Programación</span><br />Web
            </div>
          </div>
        </div>
        <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
          <div className="rounded-3xl h-full lg:bg-naranja bg-morado p-5 flex items-center flex-wrap">
            <div className="w-2/5">
              <center><img className="w-3/4" src={imagenes3.diseño_grafico_min} alt="" /></center>
            </div>
            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
              Diseño<br /><span className="font-extrabold">Gráfico</span>
            </div>
          </div>
        </div>
        <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
          <div className="rounded-3xl h-full bg-morado p-5 flex items-center flex-wrap">
            <div className="w-2/5">
              <center><img className="w-3/4" src={imagenes3.aplicacion_movil_min} alt="" /></center>
            </div>
            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
              Desarrollo<br /><span className="font-extrabold">Aplicaciones</span><br />Móviles
            </div>
          </div>
        </div>
        <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
          <div className="rounded-3xl h-full bg-azul p-5 flex items-center flex-wrap">
            <div className="w-2/5">
              <center><img className="w-3/4" src={imagenes3.produccion_multimedia_min} alt="" /></center>
            </div>
            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
              Producción<br /><span className="font-extrabold">Multimedia</span>
            </div>
          </div>
        </div>
        <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
          <div className="rounded-3xl h-full lg:bg-naranja bg-azul p-5 items-center flex flex-wrap">
            <div className="w-2/5">
              <center><img className="w-3/4" src={imagenes3.soporte_tecnico_min} alt="" /></center>
            </div>
            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
              Soporte<br /><span className="font-extrabold">Técnico y<br /></span>Redes
            </div>
          </div>
        </div>
        <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
          <div className="rounded-3xl h-full lg:bg-morado bg-azul p-5 items-center flex flex-wrap">
            <div className="w-2/5">
              <center><img className="w-3/4" src={imagenes3.analisis_datos_min} alt="" /></center>
            </div>
            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
              Ciencia de<br /><span className="font-extrabold">Datos</span>
            </div>
          </div>
        </div>
        <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
          <div className="rounded-3xl h-full bg-azul p-5 flex items-center flex-wrap">
            <div className="w-2/5">
              <center><img className="w-3/4" src={imagenes3.cloud_computer_min} alt="" /></center>
            </div>
            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
              Cloud<br /><span className="font-extrabold">Computing</span>
            </div>
          </div>
        </div>
        <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
          <div className="rounded-3xl h-full bg-naranja p-5 flex items-center flex-wrap">
            <div className="w-2/5">
              <center><img className="w-3/4" src={imagenes3.diseño_ux_ui_min} alt="" /></center>
            </div>
            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
              Diseño<br /><span className="font-extrabold">UI/UX</span>
            </div>
          </div>
        </div>
        <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
          <div className="rounded-3xl h-full lg:bg-morado bg-naranja p-5 items-center flex flex-wrap">
            <div className="w-2/5">
              <center><img className="w-3/4" src={imagenes3.creacion_videojuegos_min} alt="" /></center>
            </div>
            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
              Creación de<br /><span className="font-extrabold">Videojuegos</span>
            </div>
          </div>
        </div>
        <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
          <div className="rounded-3xl h-full lg:bg-azul bg-naranja p-5 items-center flex flex-wrap">
            <div className="w-2/5">
              <center><img className="w-3/4" src={imagenes3.blockchain} alt="" /></center>
            </div>
            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
              Blockchain<br /><span className="font-extrabold">Web3</span>
            </div>
          </div>
        </div>
        <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
          <div className="rounded-3xl h-full bg-naranja p-5 flex items-center flex-wrap">
            <div className="w-2/5">
              <center><img className="w-3/4" src={imagenes3.comercio_electronico_min} alt="" /></center>
            </div>
            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
              Comercio<br /><span className="font-extrabold">Electrónico</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Carreras;