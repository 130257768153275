import Carousel from "../components/Carousel";
import imagenes2 from '../images/Home/2_/imagenes2';
import imagenes3 from '../images/Home/3_/imagenes3';
import imagenesBootcamp from '../images/Home/4_Bootcamp/imagenesBootcamp';
import Carreras from '../views/Carreras';
import Carousel2 from '../components/CarouselAliados';
import Footer from '../components/footer';
import CookieConsent from "react-cookie-consent";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Inicio() {
  return (
    <>
      <CookieConsent
        className="text-center"
        containerClasses="text-center"
        location="bottom"
        buttonText="Aceptar"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#0f6399" }}
        buttonStyle={{ color: "white", fontSize: "13px", background: "#f08d06" }}
        expires={150}
      >
        <h1 className="text-center">Utilizamos cookies para optimizar nuestro sitio web y nuestro servicio.</h1>
      </CookieConsent>
      <Carousel />
      <div className="mt-10 md:mt-20 mb-10 md:mb-20">
        <h1 className='text-center text-3xl md:text-5xl'>¿Qué es <span className='text-morado font-bold'>TIC</span><span className='font-bold text-azul'>ONG</span><span className='font-bold text-naranja'>LE</span>?</h1>
        <div className='w-full flex justify-center items-center'>
          <div className='w-3/4 text-justify mt-5'>Es un programa integral para que los jóvenes en situación de vulnerabilidad educativa-laboral cuenten con oportunidades para mejorar sus condiciones socioeconómicas a través de la <span className='font-bold'>formación, el empleo y emprendimiento</span></div>
        </div>
        <div className="lg:flex mt-10">
          <div className="w-full lg:w-2/6 mt-5" data-aos="zoom-in">
            <div className="flex items-center justify-center h-full">
              <div className="w-9/12 bg-morado rounded-3xl border border-gray-200 shadow-md h-full">
                <h1 className='text-center m-10 font text-3xl md:text-4xl font-semibold text-white'>Joven<br />Integral</h1>
                <center><img className='w-1/2' src={imagenes2.joven_integral2_min} alt="" /></center>
                <div className="w-full p-10 text-white text-center">
                  <p>Con planes de formación prácticos y con la metodología de "aprender - haciendo", abarcamos 4 áreas: formación técnica, desarrollo emocional a través de un programa neurolingüistico, inglés y el ABC de las finanzas.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-2/6 mt-5" data-aos="zoom-in">
            <div className="flex items-center justify-center h-full">
              <div className="w-9/12 bg-azul rounded-3xl border border-gray-200 shadow-md h-full">
                <h1 className='text-center m-10 font text-3xl md:text-4xl font-semibold text-white'>Joven<br />Productivo</h1>
                <center><img className='w-1/2' src={imagenes2.joven_productivo_min} alt="" /></center>
                <div className="w-full p-10 text-white text-center">
                  <p>Promoviendo el empleo independiente o freelance, damos el impulso que los jóvenes necesitan para hacer un trabajo por proyecto o tareas especificas, poniendo a la disposición del mercado sus servicios y gestionando la labor con los socios y aliados.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-2/6 mt-5" data-aos="zoom-in">
            <div className="flex items-center justify-center h-full">
              <div className="w-9/12 bg-naranja rounded-3xl border border-gray-200 shadow-md h-full">
                <h1 className='text-center m-10 font text-3xl md:text-4xl font-semibold text-white'>Joven<br />Emprendedor</h1>
                <center><img className='w-1/2' src={imagenes2.emprendedor_min} alt="" /></center>
                <div className="w-full p-10 text-white text-center">
                  <p>Con la experiencia adquirida el joven emprenderá con su propio negocio, brindándole asistencia administrativa y financiera, espacios de coworking, gestión de alianzas para hacer negocios y otros servicios que le ayudan a formalizarse y crecer.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-sky-200 p-10">
        <h1 className='text-center text-3xl md:text-5xl mb-10'>Conocemos tu <span className='text-morado font-bold'>Len</span><span className='font-bold text-azul'>gua</span><span className='font-bold text-naranja'>je</span></h1>
        <div className="w-full flex justify-center items-center mb-10" data-aos="zoom-in">
          <div className="flex justify-center items-center p-5 lg:p-10 bg-white w-full md:w-3/4 rounded-lg">
            <iframe className='rounded-md w-full' width="560" height="315" src="https://www.youtube.com/embed/QC4jNXstf98" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
        <div className="flex justify-center items-center mb-5" data-aos="zoom-in">
          <div className='rounded-3xl bg-azul p-3 lg:p-10 w-full lg:w-9/12'>
            <h1 className='text-white text-2xl md:text-3xl text-justify'>Ayudamos a las organizaciones y empresas a potenciar su trabajo aprovechando las tecnologías digitales.</h1>
          </div>
        </div>
      </div>
      <div className="w-full p-10 text-center" style={{ 'backgroundImage': 'url("' + imagenes3.Fondo_min + '")', "backgroundSize": "100%" }}>
        <div className='flex items-center justify-center'>
          <h1 className='text-center text-2xl md:text-3xl mb-10 w-full md:w-9/12'>Con <span className='text-morado font-bold'>TIC</span><span className='font-bold text-azul'>ONG</span><span className='font-bold text-naranja'>LE</span> nos preparamos recibiendo cursos, certificaciones y talleres prácticos con profesionales y expertos en las áreas de demanda digital.</h1>
        </div>
        <Carreras />
      </div>
      {/* <div className="w-full p-20 text-center" style={{ 'backgroundImage': 'url("' + imagenesBootcamp.fondo_bootcamp_min + '")', "backgroundSize": "100%" }}>
        <div class="gap-12 columns-1 lg:columns-2">
          <center><img data-aos="zoom-in" src={imagenesBootcamp.L_Bootcamp_min} alt="" /></center>
          <div className='' data-aos="zoom-in">
            <div>
              <h1 className='text-white text-2xl md:text-4xl'>Participa en nuestros Bootcamp</h1>
              <br /><br />
              <a href="#" className='p-3 md:p-5 bg-morado text-white rounded-xl font-bold text-xl lg:text-3xl'>Aplica ya</a>
            </div>
          </div>
        </div>
      </div> */}
      <div className="bg-white p-5 flex justify-center items-center" data-aos="zoom-in">
        <Carousel2 />
      </div>
      <div className="w-full p-20 bg-azul">
        <h1 className='text-center text-white text-4xl'>Sedes</h1>
        <Footer />
      </div>
    </>
  );
}

export default Inicio;