import conexion_san_benito_min from './conexion_san_benito-min.png'
import conexion_soyapango_min from "./conexion_soyapango-min.png"
import conexion_la_libertad_min from "./conexion_la_libertad-min.png"
import fondo_sedes_min from "./fondo_sedes-min.jpg"

export default {
    "conexion_san_benito_min": conexion_san_benito_min,
    "conexion_soyapango_min": conexion_soyapango_min,
    "conexion_la_libertad_min": conexion_la_libertad_min,
    "fondo_sedes_min": fondo_sedes_min
}