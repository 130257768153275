import React from "react";
import BannerUneteTicongleros from '../images/UneteTicongleros/BannerUneteTicongleros';
import Pag2 from '../images/UneteTicongleros/Pag_2/pag2';
import Pag3 from '../images/UneteTicongleros/Pag_3/Con texto/Pag3';
import Carreras from './Carreras';
import Footer from '../components/footer';
import ReactTypingEffect from 'react-typing-effect';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function UneteTicongleros() {
    return (
        <>
            <div className="w-full text-center relative flex justify-center">
                <img src={BannerUneteTicongleros.Preparate} />
                <div className="absolute text-white" style={{"top":"15%", "bottom":"15%"}}>
                    <h1 className="text-lg sm:text-4xl md:text-5xl lg:text-7xl xl:text-8xl">Prepárate para ser un<br /><span className="font-bold">Profesional Digital</span></h1>
                    <div className="text-yellow-400 text-xl sm:text-3xl md:text-4xl lg:text-6xl xl:text-7xl font-bold">
                        <ReactTypingEffect
                            text={["Certificados digitales", "Emprendedores", "Freelancers"]}
                            speed={60}
                            eraseSpeed={70}
                            typingDelay={1500}
                            eraseDelay={1500}
                        />
                    </div>
                    <h4 className="text-xs sm:text-xl sm:mt-10 sm:mb-10 md:text-2xl lg:text-4xl xl:text-4xl mb-3 md:ml-10 md:mr-10">Workshops y cursos intensivos de programación y desarrollo web, marketing digital, producción multimedia, soporte técnico y redes</h4>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSd9qyyYIZ5_YRWR2AaE9gxORTsLmmXwFzHdddZBiixolbXESg/viewform" target="_blank" className="p-2 sm:p-3 lg:p-5 rounded-md bg-naranja text-white text-xs sm:text-3xl lg:text-3xl font-semibold hover:bg-amber-600">Aplicar</a>
                </div>
            </div>
            <div className="w-full bg-slate-300 p-10">
                <h1 className="text-center text-5xl font-semibold mt-5">¿Por qué debería unirme a <span className='text-morado font-bold'>TIC</span><span className='font-bold text-azul'>ONG</span><span className='font-bold text-naranja'>LE</span>?</h1>

                <h1 className="text-center text-4xl mt-10">Tu portafolio es tu mejor currículo</h1>

                <div class="flex flex-wrap mt-5">
                    <div className="p-5 w-full sm:w-1/2 lg:w-1/3" data-aos="zoom-in">
                        <div className="rounded-3xl p-5 flex items-center flex-wrap h-full">
                            <div className="w-1/2">
                                <center><img className="w-2/4 sm:3/4" src={Pag2.formacion_integral} alt="" /></center>
                            </div>
                            <div className="text-justify text-slate-500 xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-2xl w-1/2">
                                Formación<br /><span className="font-bold">Integral</span>
                            </div>
                            <p className="text-slate-500 text-center text-xl mt-5">
                                Complementa tu especialización digital con un programa de desarrollo emocional, finanzas básicas e ingles
                            </p>
                        </div>
                    </div>
                    <div className="p-5 w-full sm:w-1/2 lg:w-1/3" data-aos="zoom-in">
                        <div className="rounded-3xl p-5 flex items-center flex-wrap h-full">
                            <div className="w-1/2">
                                <center><img className="w-2/4 sm:3/4" src={Pag2.aprende_creando} alt="" /></center>
                            </div>
                            <div className="text-justify text-slate-500 xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-2xl w-1/2">
                                Aprende<br /><span className="font-bold">Creando</span>
                            </div>
                            <p className="text-slate-500 text-center text-xl mt-5">
                                Aprendizaje práctico trabajando en proyectos reales de empresas y organizaciones socias y aliadas
                            </p>
                        </div>
                    </div>
                    <div className="p-5 w-full sm:w-1/2 lg:w-1/3" data-aos="zoom-in">
                        <div className="rounded-3xl p-5 flex items-center flex-wrap h-full">
                            <div className="w-1/2">
                                <center><img className="w-2/4 sm:3/4" src={Pag2.acceso_laboral} alt="" /></center>
                            </div>
                            <div className="text-justify text-slate-500 xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-2xl w-1/2">
                                Acceso<br /><span className="font-bold">Laboral</span>
                            </div>
                            <p className="text-slate-500 text-center text-xl mt-5">
                                Forma parte de la red de freelancers de <span className="font-bold">TICONGLE</span> para hacer trabajos por proyectos
                            </p>
                        </div>
                    </div>
                    <div className="p-5 w-full sm:w-1/2 lg:w-1/3" data-aos="zoom-in">
                        <div className="rounded-3xl p-5 flex items-center flex-wrap h-full">
                            <div className="w-1/2">
                                <center><img className="w-2/4 sm:3/4" src={Pag2.apoyo_profesional} alt="" /></center>
                            </div>
                            <div className="text-justify text-slate-500 xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-2xl w-1/2">
                                Apoyo<br /><span className="font-bold">Profesional</span>
                            </div>
                            <p className="text-slate-500 text-center text-xl mt-5">
                                Aprende y participa en workshops y eventos exclusivos con profesionales y expertos de la industria
                            </p>
                        </div>
                    </div>
                    <div className="p-5 w-full sm:w-1/2 lg:w-1/3" data-aos="zoom-in">
                        <div className="rounded-3xl p-5 flex items-center flex-wrap h-full">
                            <div className="w-1/2">
                                <center><img className="w-2/4 sm:3/4" src={Pag2.emprende_ideas} alt="" /></center>
                            </div>
                            <div className="text-justify text-slate-500 xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-2xl w-1/2">
                                Emprende<br /><span className="font-bold">Tu idea</span>
                            </div>
                            <p className="text-slate-500 text-center text-xl mt-5">
                                Transforma tu carrera tanto si quieres trabajar en el mundo digital, como si quieres lanzar tu propia empresa
                            </p>
                        </div>
                    </div>
                    <div className="p-5 w-full sm:w-1/2 lg:w-1/3" data-aos="zoom-in">
                        <div className="rounded-3xl p-5 flex items-center flex-wrap h-full">
                            <div className="w-1/2">
                                <center><img className="w-2/4 sm:3/4" src={Pag2.zona_coworkin} alt="" /></center>
                            </div>
                            <div className="text-justify text-slate-500 xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-2xl w-1/2">
                                Zona<br /><span className="font-bold">Coworking</span>
                            </div>
                            <p className="text-slate-500 text-center text-xl mt-5">
                                Cuenta con espacios para trabajar en tus propios proyectos y pertenece a la comunidad colaborando e intercambiando conocimientos e ideas
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-white p-10 mt-5">
                <h1 className="text-center text-4xl font-semibold mb-5">Desarróllate Profesionalmente</h1>
                <br />
                <center><span className="text-center text-4xl">Los valores del Ticonglero son su filosofía de trabajo</span></center>
                <div class="flex flex-wrap mt-10">
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl bg-morado p-5 flex items-center flex-wrap h-full w-11/12 max-w-sm">
                            <div className="w-full flex justify-center">
                                <img className="w-auto" src={Pag3.integral} alt="" />
                            </div>
                            <div className="text-center text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-full">
                                <span className="font-bold">Integridad</span><br />
                                Hacemos lo que
                                <br />
                                decimos y prometemos
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl lg:bg-azul bg-morado p-5 flex items-center flex-wrap h-full w-11/12 max-w-sm">
                            <div className="w-full flex justify-center">
                                <img className="w-auto" src={Pag3.Profesionalismo} alt="" />
                            </div>
                            <div className="text-center text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-full h-full">
                                <span className="font-bold">Profesionalismo</span><br />
                                Trabajamos con objetividad y responsabilidad
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl lg:bg-naranja bg-azul p-5 flex items-center flex-wrap h-full w-11/12 max-w-sm">
                            <div className="w-full flex justify-center">
                                <img className="w-auto" src={Pag3.lealtad} alt="" />
                            </div>
                            <div className="text-center text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-full">
                                <span className="font-bold">Lealtad</span><br />
                                Nos comprometemos a ser honestos y éticos
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl lg:bg-morado bg-azul p-5 flex items-center flex-wrap h-full w-11/12 max-w-sm">
                            <div className="w-full flex justify-center">
                                <img className="w-auto" src={Pag3.pasion} alt="" />
                            </div>
                            <div className="text-center text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-full">
                                <span className="font-bold">Pasión</span><br />
                                Creemos y nos interesa lo que hacemos
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl lg:bg-azul bg-naranja p-5 flex items-center flex-wrap h-full w-11/12 max-w-sm">
                            <div className="w-full flex justify-center">
                                <img className="w-auto" src={Pag3.compromiso} alt="" />
                            </div>
                            <div className="text-center text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-full">
                                <span className="font-bold">Compromiso</span><br />
                                Actuamos para cumplir nuestras metas
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl bg-naranja p-5 flex items-center flex-wrap h-full w-11/12 max-w-sm">
                            <div className="w-full flex justify-center">
                                <img className="w-auto" src={Pag3.trabajo_en_equipo} alt="" />
                            </div>
                            <div className="text-center text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-full">
                                <span className="font-bold">Trabajo en equipo</span><br />
                                Nuestro espíritu de equipo nos da la ventaja
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-slate-300 p-10">
                <h1 className="text-center text-4xl font-semibold mt-5 mb-10">Nuestras carreras</h1>
                <Carreras />
            </div>
            <div className="w-full p-20 bg-azul">
                <h1 className='text-center text-white text-4xl'>¿Dónde quieres estudiar?</h1>
                <Footer />
            </div>
        </>
    );
}

export default UneteTicongleros;