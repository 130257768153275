
import imagenesSedes from '../images/Home/5_Sedes/imagenesSedes';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faFacebook, faTwitter, faInstagram, faLinkedin, faDiscord } from "@fortawesome/free-brands-svg-icons";

function Footer() {

  var y = new Date().getFullYear();

    return (
      <>
        <div class="flex flex-wrap mt-10">
          <div className='p-5 w-full lg:w-1/3' data-aos="zoom-in">
            <div>
              <center><a href="https://goo.gl/maps/gbvpMg4CaMLvy7YK7" target="_blank"><img src={imagenesSedes.conexion_san_benito_min} alt="" /></a></center>
              <p className="text-justify">
              <a href="https://goo.gl/maps/gbvpMg4CaMLvy7YK7" target="_blank"><h1 className='mt-10 text-center text-white leading-loose'><span className='p-2 bg-sky-400 rounded'>Dirección</span>&nbsp;&nbsp;Calle La Reforma #249 Col. San Benito, San Salvador.</h1></a>
              </p>
            </div>
          </div>
          <div className='p-5 w-full lg:w-1/3' data-aos="zoom-in">
            <div>
              <center><a href="https://goo.gl/maps/zhaXtUQoq5fJM4sY7" target="_blank"><img src={imagenesSedes.conexion_soyapango_min} alt="" /></a></center>
              <p className="text-justify">
              <a href="https://goo.gl/maps/zhaXtUQoq5fJM4sY7" target="_blank"><h1 className='mt-10 text-center text-white leading-loose'><span className='p-2 bg-sky-400 rounded'>Dirección</span>&nbsp;&nbsp;Centro Comercial Plaza Venecia, local 109. Carretera de Oro. Frente a U.M. ISSS Soyapango.</h1></a>
              </p>
            </div>
          </div>
          <div className='p-5 w-full lg:w-1/3' data-aos="zoom-in">
            <div>
              <center><a href="https://goo.gl/maps/YAg4fT1qGptUao7w9" target="_blank"><img src={imagenesSedes.conexion_la_libertad_min} alt="" /></a></center>
              <p className="text-justify">
              <a href="https://goo.gl/maps/YAg4fT1qGptUao7w9" target="_blank"><h1 className='mt-10 text-center text-white leading-loose'><span className='p-2 bg-sky-400 rounded'>Dirección</span>&nbsp;&nbsp;Calle Gerardo Barrios y Ave. Simón Bolívar No. 21-7, La Libertad.</h1></a>
              </p>
            </div>
          </div>
        </div>
        <div className='border border-white'></div>
        <div class="gap-12 columns-1 lg:columns-2 mt-10">
          <div className='p-5' data-aos="zoom-in">
            <div>
              <p className="text-justify">
                <h1 className='text-center text-white leading-loose'>Todos los derechos reservados {y} <span className='font-semibold'>TICONGLE</span><br />Un programa de <a href='https://www.conexion.sv/' target="_blank" className='font-semibold text-naranja hover:text-amber-500'>CONEXION El Salvador</a></h1>
              </p>
            </div>
          </div>
          <div className='p-5' data-aos="zoom-in">
            <div className='h-full'>
              <div className="flex justify-center items-center h-full text-4xl">
                <a href="https://www.facebook.com/conexion.sv/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebook} className="text-white m-3" />
                </a>
                <a href="https://twitter.com/conexion_sv?lang=es" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTwitter} className="text-white m-3" />
                </a>
                <a href="https://www.instagram.com/conexion.sv/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} className="text-white m-3" />
                </a>
                <a href="https://www.linkedin.com/company/conexion_sv/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} className="text-white m-3" />
                </a>
                <a href="https://discord.gg/Kgr94DxTNw" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faDiscord} className="text-white m-3" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  
  export default Footer;
  
  