import uno from './1_-min.png'
import dos from './2_-min.png'
import tres from './3_-min.png'
import cuatro from './4_-min.png'
import cinco from './5_-min.png'
import seis from './6_-min.png'

export default {
    "uno": uno,
    "dos": dos,
    "tres": tres,
    "cuatro": cuatro,
    "cinco": cinco,
    "seis": seis
}