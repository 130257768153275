import React, { useState, Fragment } from "react";
import BannerUneteEmpresas from "../images/UneteEmpresas/BannerUneteEmpresas";
import Pag3 from '../images/UneteTicongleros/Pag_3/Con texto/Pag3';
import SegundaParte from '../images/UneteEmpresas/2da parte/2daParte';
import TerceraParte from '../images/UneteEmpresas/3ra parte/3raParte';
import imagenes3 from '../images/Home/3_/imagenes3';
import Carreras from './Carreras';
import Footer from '../components/footer';
import ImagenesContrataEmpresas from "../images/ContrataEmpresas/2daparte/ImagenesContrataEmpresas";
import BannerContrataEmpresas from "../images/ContrataEmpresas/BannerContrataEmpresas";

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function ContrataEmpresas() {
    return (
        <>
            <div className="w-full text-center relative flex" data-aos="fade-down">
                <img src={BannerContrataEmpresas.Banner} />
                <div className="absolute top-1/4 bottom-1/4 text-white">
                    <h1 className="text-lg sm:text-4xl md:text-5xl lg:text-7xl xl:text-8xl">Contrata nuestros<br /><span className="font-bold">SERVICIOS</span></h1>
                    <h4 className="text-xs sm:text-xl sm:mb-10 md:text-2xl lg:text-4xl xl:text-4xl mb-3">Preparamos tecnológicamente a jóvenes para ayudar a las empresas y organizaciones a potenciar su negocio y trabajo</h4>
                    <a href="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfFLSXp_i8zFJ-fXQ_kuU7IN4rkydHoqanxVSoJIcSCrL3drw/formResponse" target="_blank" className="p-2 sm:p-3 lg:p-5 rounded-md bg-naranja text-white text-xs sm:text-xl lg:text-3xl font-semibold hover:bg-amber-600">Ponte en contacto</a>
                </div>
            </div>
            <div className="w-full p-10">
                <h1 className="text-center text-4xl font-semibold">¿Por qué debería contratar?</h1>
                <h1 className="text-center text-4xl mt-5">Conocemos tu lenguaje</h1>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div className="p-5" data-aos="zoom-in">
                        <div className="rounded-3xl h-full p-5 text-center">
                            <center><img src={ImagenesContrataEmpresas.medalla} alt="" className="w-1/4" /></center>
                            <h3 className="text-center mt-5 text-slate-500 text-xl font-extrabold">Los valores del Ticonglero son su filosofía de trabajo</h3>
                            <p className="text-slate-500 text-xl">
                                Pasión, integridad, profesionalismo, lealtad, compromiso y trabajo en equipo
                            </p>
                        </div>
                    </div>
                    <div className="p-5" data-aos="zoom-in">
                        <div className="rounded-3xl h-full p-5 text-center">
                            <center><img src={ImagenesContrataEmpresas.like} alt="" className="w-1/4" /></center>
                            <h3 className="text-center mt-5 text-slate-500 text-xl font-extrabold">Excelencia técnica especializada</h3>
                            <p className="text-slate-500 text-xl">
                                Los Ticongleros se preparan recibiendo cursos y talleres prácticos con profesionales y expertos para responder a las necesidades del mercado
                            </p>
                        </div>
                    </div>
                    <div className="p-5" data-aos="zoom-in">
                        <div className="rounded-3xl h-full p-5 text-center">
                            <center><img src={ImagenesContrataEmpresas.graduado} alt="" className="w-1/4" /></center>
                            <h3 className="text-center mt-5 text-slate-500 text-xl font-extrabold">Aprendizaje en base a proyectos</h3>
                            <p className="text-slate-500 text-xl">
                                Desde el principio, los Ticongleros trabajan con proyectos reales en su proceso de formación
                            </p>
                        </div>
                    </div>
                    <div className="p-5" data-aos="zoom-in">
                        <div className="rounded-3xl h-full p-5 text-center">
                            <center><img src={ImagenesContrataEmpresas.gerente} alt="" className="w-1/4" /></center>
                            <h3 className="text-center mt-5 text-slate-500 text-xl font-extrabold">Asesorados por profesionales</h3>
                            <p className="text-slate-500 text-xl">
                                El equipo de trabajo dedicado a tu proyecto es liderado por profesionales con experiencia laboral, garantizando productos y servicios de calidad
                            </p>
                        </div>
                    </div>
                    <div className="p-5" data-aos="zoom-in">
                        <div className="rounded-3xl h-full p-5 text-center">
                            <center><img src={ImagenesContrataEmpresas.checklist} alt="" className="w-1/4" /></center>
                            <h3 className="text-center mt-5 text-slate-500 text-xl font-extrabold">Selección rigurosa</h3>
                            <p className="text-slate-500 text-xl">
                                Nuestro proceso de admisión permite seleccionar a jóvenes que pueden convertirse en profesionales destacados
                            </p>
                        </div>
                    </div>
                    <div className="p-5" data-aos="zoom-in">
                        <div className="rounded-3xl h-full p-5 text-center">
                            <center><img src={ImagenesContrataEmpresas.oportunidades} alt="" className="w-1/4" /></center>
                            <h3 className="text-center mt-5 text-slate-500 text-xl font-extrabold">Danos una oportunidad</h3>
                            <p className="text-slate-500 text-xl">
                                El Ticonglero no lo sabe todo, pero es capaz de hacer todo lo que le pidas porque siempre esta aprendiendo cosas nuevas
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full p-10 text-center" data-aos="fade-down" style={{ 'backgroundImage': 'url("' + imagenes3.Fondo_min + '")', "backgroundSize": "100%" }}>
                <div className='flex items-center justify-center'>
                    <h1 className='text-center text-3xl md:text-4xl mb-10 w-full md:w-9/12 font-bold'>¿Qué servicios pueden contratar?</h1>
                </div>
                <div class="flex flex-wrap">
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full bg-morado p-5 flex items-center flex-wrap w-11/12 max-w-sm">
                            <div className="w-2/5">
                                <img className="w-3/4" src={imagenes3.marketing_digital_min} alt="" />
                            </div>
                            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                Estrategia<br /><span className="font-bold">Marketing</span><br />Digital
                            </div>
                            <div className="text-white text-center text-xl mt-1 h-full">
                                Vamos mas allá de tu presencia digital, sabemos como posicionarte, como mejorar la atención al cliente y como aumentar tus ventas
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full lg:bg-azul bg-morado p-5 flex items-center flex-wrap w-11/12 max-w-sm">
                            <div className="w-2/5">
                                <img className="w-3/4" src={imagenes3.desarrollo_web_min} alt="" />
                            </div>
                            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                Desarrollo y<br /><span className="font-bold">Programación</span><br />Web
                            </div>
                            <div className="text-white text-center text-xl mt-1 h-full">
                                Desarrollamos e implementamos soluciones web de vanguardia
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full lg:bg-naranja bg-morado p-5 flex items-center flex-wrap w-11/12 max-w-sm">
                            <div className="w-2/5">
                                <img className="w-3/4" src={imagenes3.diseño_grafico_min} alt="" />
                            </div>
                            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                Diseño<br /><span className="font-bold">Gráfico</span>
                            </div>
                            <div className="text-white text-center text-xl mt-1 h-full">
                                Diseñamos y construimos la próxima generación de productos digitales
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full bg-morado p-5 flex items-center flex-wrap w-11/12 max-w-sm">
                            <div className="w-2/5">
                                <img className="w-3/4" src={imagenes3.aplicacion_movil_min} alt="" />
                            </div>
                            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                Desarrollo<br /><span className="font-bold">Aplicaciones</span><br />Móviles
                            </div>
                            <div className="text-white text-center text-xl mt-1 h-full">
                                Desarrollamos herramientas móviles corporativas y de servicios con diseños e interfaces intuitivas
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full bg-azul p-5 flex items-center flex-wrap w-11/12 max-w-sm">
                            <div className="w-2/5">
                                <img className="w-3/4" src={imagenes3.produccion_multimedia_min} alt="" />
                            </div>
                            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                Producción<br /><span className="font-bold">Multimedia</span>
                            </div>
                            <div className="text-white text-center text-xl mt-1 h-full">
                                Diseñamos y creamos productos que incluyen videos, sonidos, animaciones, fotografías y textos para que transmitas tu información
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full lg:bg-naranja bg-azul p-5 flex items-center flex-wrap w-11/12 max-w-sm">
                            <div className="w-2/5">
                                <img className="w-3/4" src={imagenes3.soporte_tecnico_min} alt="" />
                            </div>
                            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                Soporte<br /><span className="font-bold">Técnico</span><br />y Redes
                            </div>
                            <div className="text-white text-center text-xl mt-1 h-full">
                                Damos mantenimiento a tu equipo para que no pongas en riesgo la productividad de tu empresa u organización
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full lg:bg-morado bg-azul p-5 flex items-center flex-wrap w-11/12 max-w-sm">
                            <div className="w-2/5">
                                <img className="w-3/4" src={imagenes3.analisis_datos_min} alt="" />
                            </div>
                            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                Ciencia de<br /><span className="font-bold">Datos</span>
                            </div>
                            <div className="text-white text-center text-xl mt-1 h-full">
                                Extraemos, analizamos, manipulamos y representamos datos como una estrategia de negocio y para la gestión pública
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full bg-azul p-5 flex items-center flex-wrap w-11/12 max-w-sm">
                            <div className="w-2/5">
                                <img className="w-3/4" src={imagenes3.cloud_computer_min} alt="" />
                            </div>
                            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                Cloud<br /><span className="font-bold">Computing</span>
                            </div>
                            <div className="text-white text-center text-xl mt-1 h-full">
                                Nos especializamos en implementación, gestión, soporte y monitoreo de plataformas de servicio en la nube
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full bg-naranja p-5 flex items-center flex-wrap w-11/12 max-w-sm">
                            <div className="w-2/5">
                                <img className="w-3/4" src={imagenes3.diseño_ux_ui_min} alt="" />
                            </div>
                            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                Diseño<br /><span className="font-bold">UI/UX</span>
                            </div>
                            <div className="text-white text-center text-xl mt-1 h-full">
                                Ofrecemos a los usuarios una experiencia ágil y atractiva con diseños intuitivos que llevan al usuario a realizar la acción que esperamos
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full lg:bg-morado bg-naranja p-5 flex items-center flex-wrap w-11/12 max-w-sm">
                            <div className="w-2/5">
                                <img className="w-3/4" src={imagenes3.creacion_videojuegos_min} alt="" />
                            </div>
                            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                Creación de<br /><span className="font-bold">Videojuegos</span>
                            </div>
                            <div className="text-white text-center text-xl mt-1 h-full">
                                Creamos videojuegos desde el concepto inicial hasta su version final: concepción, diseño, planificación, producción y pruebas
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full lg:bg-azul bg-naranja p-5 flex items-center flex-wrap w-11/12 max-w-sm">
                            <div className="w-2/5">
                                <img className="w-3/4" src={imagenes3.blockchain} alt="" />
                            </div>
                            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                Blockchain <br /><span className="font-bold">Web3</span>
                            </div>
                            <div className="text-white text-center text-xl mt-1 h-full">
                                Investigamos y experimentamos para conectar objetos a Internet e intercambiar datos, conectando el mundo virtual con el real
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5 flex justify-center items-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full bg-naranja p-5 flex items-center flex-wrap w-11/12 max-w-sm">
                            <div className="w-2/5">
                                <img className="w-3/4" src={imagenes3.comercio_electronico_min} alt="" />
                            </div>
                            <div className="text-justify text-white xl:text-3xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                Comercio<br /><span className="font-bold">Electrónico</span>
                            </div>
                            <div className="text-white text-center text-xl mt-1 h-full">
                                Usamos las mejores herramientas para que tu negocio crezca rápidamente con una tienda en linea. ¡Comienza a vender por Internet!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full p-20 text-center bg-celeste" data-aos="fade-down">
                <div class="flex flex-wrap">
                    <div className="lg:w-1/2 w-full" data-aos="zoom-in">
                        <h1 className='text-3xl md:text-5xl text-justify font-semibold'>Contrata <span className='text-morado font-bold'>TIC</span><span className='font-bold text-azul'>ONG</span><span className='font-bold text-naranja'>LE</span></h1>
                        <br />
                        <h1 className="text-xl md:text-2xl text-gray text-justify">Nuestra meta es brindarte un servicio profesional y de calidad para potenciar tu empresa u organización aprovechando las tecnologías digitales</h1>
                    </div>
                    <div className="lg:w-1/2 w-full flex justify-center items-center" data-aos="zoom-in">
                        <a href="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfFLSXp_i8zFJ-fXQ_kuU7IN4rkydHoqanxVSoJIcSCrL3drw/formResponse" target="_blank" className='p-3 md:p-5 bg-azul hover:bg-sky-700 text-white rounded-xl font-bold text-xl lg:text-3xl mt-5'>Ponte en contacto</a>
                    </div>
                </div>
            </div>
            <div className="w-full p-20 text-center">
                <div class="flex flex-wrap">
                    <div className="w-full" data-aos="zoom-in">
                        <h1 className='text-3xl md:text-5xl text-justify font-semibold'>¿Hablamos?</h1>
                        <br />
                        <h1 className="text-xl md:text-2xl text-gray text-justify">Contacta con nosotros al correo <a href="mailto:ticongle@conexion.sv" className="text-azul hover:text-sky-600 font-semibold">ticongle@conexion.sv</a><br />y nos comunicaremos contigo</h1>
                    </div>
                </div>
            </div>
            <div className="w-full p-20 bg-azul">
                <h1 className='text-center text-white text-4xl'>Sedes</h1>
                <Footer />
            </div>
        </>
    );
}

export default ContrataEmpresas;