import medalla from './1_medalla-min.png'
import like from './2_like-min.png'
import graduado from './/3_graduado-min.png'
import gerente from './4_gerente-min.png'
import checklist from './5_checklits-min.png'
import oportunidades from './6_oportunidades-min.png'

export default {
    "medalla": medalla,
    "like": like,
    "graduado": graduado,
    "gerente": gerente,
    "checklist": checklist,
    "oportunidades": oportunidades
}