import compromiso from './compromiso-min.png'
import integral from './integral-min.png'
import lealtad from './lealtad-min.png'
import pasion from './pasion-min.png'
import Profesionalismo from './Profesionalismo-min.png'
import trabajo_en_equipo from './trabajo_en_equipo-min.png'

export default {
    "compromiso": compromiso,
    "integral": integral,
    "lealtad": lealtad,
    "pasion": pasion,
    "Profesionalismo": Profesionalismo,
    "trabajo_en_equipo": trabajo_en_equipo
}