import React, { useState, Fragment } from "react";
import BannerUneteEmpresas from "../images/UneteEmpresas/BannerUneteEmpresas";
import Pag3 from '../images/UneteTicongleros/Pag_3/Con texto/Pag3';
import SegundaParte from '../images/UneteEmpresas/2da parte/2daParte';
import TerceraParte from '../images/UneteEmpresas/3ra parte/3raParte';
import Carreras from './Carreras';
import Footer from '../components/footer';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function UneteEmpresas() {
    return (
        <>
            <div className="w-full text-center relative flex justify-center">
                <img src={BannerUneteEmpresas.Banner_eneramos_talento} />
                <div className="absolute top-1/4 bottom-1/4 text-white">
                    <h1 className="text-lg sm:text-4xl md:text-5xl lg:text-7xl xl:text-8xl">Generamos<br /><span className="font-bold">Talento Tecnológico</span></h1>
                    <div className="w-full flex justify-center"><h4 className="text-xs sm:text-xl sm:mb-10 md:text-2xl lg:text-4xl xl:text-4xl mb-3 w-3/4">Adoptando el modelo <span className="text-semibold">TICONGLE</span> contribuyes a mejorar la calidad de vida del joven salvadoreño</h4></div>
                    <a href="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfFLSXp_i8zFJ-fXQ_kuU7IN4rkydHoqanxVSoJIcSCrL3drw/formResponse" target="_blank" className="p-2 sm:p-3 lg:p-5 rounded-md bg-naranja hover:bg-amber-600 text-white text-xs sm:text-xl lg:text-3xl font-semibold">Ponte en contacto</a>
                </div>
            </div>
            <div className="w-full bg-slate-300 p-10">
                <h1 className="text-center text-4xl font-semibold mt-5">¿Por qué debería unirme a <span className='text-morado font-bold'>TIC</span><span className='font-bold text-azul'>ONG</span><span className='font-bold text-naranja'>LE</span>?</h1>

                <div class="flex flex-wrap mt-10">
                    <div className="w-full md:w-1/2 p-5 text-center" data-aos="zoom-in">
                        <div className="bg-white shadow-xl rounded-2xl p-5 h-full">
                            <center><img src={SegundaParte.unamonoss} alt="" /></center>
                            <h1 className="text-verde text-xl mt-5">Unámonos para que los jóvenes en situación de vulnerabilidad educativa - laboral cuenten con oportunidades reales para mejorar su calidad de vida</h1>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 p-5 text-center" data-aos="zoom-in">
                        <div className="bg-white shadow-xl rounded-2xl p-5 h-full">
                            <center><img src={SegundaParte.tu_empresa} alt="" /></center>
                            <h1 className="text-verde text-xl mt-5">Tu empresa u organización sera incluida en nuestra estrategia comunicacional como aliado que apoyan <span className="text-morado font-bold">TIC</span><span className="text-azul font-bold">ONG</span><span className="text-naranja font-bold">LE</span></h1>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 p-5 text-center" data-aos="zoom-in">
                        <div className="bg-white shadow-xl rounded-2xl p-5 h-full">
                            <center><img src={SegundaParte.acceso3} alt="" /></center>
                            <h1 className="text-verde text-xl mt-5">Tendrás acceso a espacios de convergencia para realizar actividades de tu interés con los Ticongleros</h1>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 p-5 text-center" data-aos="zoom-in">
                        <div className="bg-white shadow-xl rounded-2xl p-5 h-full">
                            <center><img src={SegundaParte.serasparte} alt="" /></center>
                            <h1 className="text-verde text-xl mt-5">Serás parte de un programa innovador de Responsabilidad Social Corporativa</h1>
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap mt-5">
                    <div className="w-full p-5" data-aos="zoom-in">
                        <div className="bg-white shadow-xl rounded-2xl p-5 h-full lg:flex lg:items-center lg:justify-center text-center">
                            <center><img src={SegundaParte.acceso5} alt="" /></center>
                            <h1 className="text-verde text-xl mt-5">Acceso a los servicios digitales con pasantías de los Ticongleros para que en su carrera de formación trabajen en tus proyectos reales</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-white p-10">
                <h1 className="text-center text-4xl font-semibold mt-5">¿Como puedo participar en <span className='text-morado font-bold'>TIC</span><span className='font-bold text-azul'>ONG</span><span className='font-bold text-naranja'>LE</span>?</h1>
                <div class="flex flex-wrap mt-10">
                    <div className="p-5 md:w-1/2 lg:w-1/3 flex justify-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full p-5 bg-morado text-center w-11/12 max-w-sm">
                            <center><img src={TerceraParte.uno} alt="" /></center>
                            <p className="text-white text-xl">
                                Contratando los servicios de los Ticongleros con tarifas preferenciales
                            </p>
                        </div>
                    </div>
                    <div className="p-5 md:w-1/2 lg:w-1/3 flex justify-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full w-11/12 max-w-sm p-5 bg-morado lg:bg-azul text-center">
                            <center><img src={TerceraParte.cuatro} alt="" /></center>
                            <p className="text-white text-xl">
                                Donando equipo o mobiliario nuevo o usado para fortalecer los talleres, las aulas de aprendizaje y los espacios de coworking
                            </p>
                        </div>
                    </div>
                    <div className="p-5 md:w-1/2 lg:w-1/3 flex justify-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full w-11/12 max-w-sm p-5 bg-azul lg:bg-naranja text-center">
                            <center><img src={TerceraParte.dos} alt="" /></center>
                            <p className="text-white text-xl">
                                Aportando financieramente para becas que contribuyan a la sostenibilidad de <span className="font-bold">TICONGLE</span>
                            </p>
                        </div>
                    </div>
                    <div className="p-5 md:w-1/2 lg:w-1/3 flex justify-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full w-11/12 max-w-sm p-5 bg-azul lg:bg-morado text-center">
                            <center><img src={TerceraParte.cinco} alt="" /></center>
                            <p className="text-white text-xl">
                                Beneficiando a un grupo de jóvenes de tu interés particular para que sean parte del modelo <span className="font-bold">TICONGLE</span>
                            </p>
                        </div>
                    </div>
                    <div className="p-5 md:w-1/2 lg:w-1/3 flex justify-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full w-11/12 max-w-sm p-5 bg-naranja lg:bg-azul text-center">
                            <center><img src={TerceraParte.tres} alt="" /></center>
                            <p className="text-white text-xl">
                                Promoviendo TICONGLE con tus propios socios, colaboradores y aliados
                            </p>
                        </div>
                    </div>
                    <div className="p-5 md:w-1/2 lg:w-1/3 flex justify-center" data-aos="zoom-in">
                        <div className="rounded-3xl h-full w-11/12 max-w-sm p-5 bg-naranja text-center">
                            <center><img src={TerceraParte.seis} alt="" /></center>
                            <p className="text-white text-xl">
                                Aportando recursos y tiempo para compartir experiencias profesionales y laborales con los Ticongleros
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full p-20 text-center bg-celeste">
                <div class="flex flex-wrap">
                    <div className="lg:w-1/2 w-full" data-aos="zoom-in">
                        <h1 className='text-3xl md:text-5xl text-justify font-semibold'>Únete a <span className='text-morado font-bold'>TIC</span><span className='font-bold text-azul'>ONG</span><span className='font-bold text-naranja'>LE</span></h1>
                        <br />
                        <h1 className="text-xl md:text-2xl text-gray text-justify">Se parte de este programa innovador para que juntos demos oportunidades reales a jovenes en situacion de vulnerabilidad educativa-laboral</h1>
                    </div>
                    <div className="lg:w-1/2 w-full flex justify-center items-center" data-aos="zoom-in">
                        <a href="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfFLSXp_i8zFJ-fXQ_kuU7IN4rkydHoqanxVSoJIcSCrL3drw/formResponse" target="_blank" className='p-3 md:p-5 bg-azul hover:bg-sky-700 text-white rounded-xl font-bold text-xl lg:text-3xl mt-5'>Ponte en contacto</a>
                    </div>
                </div>
            </div>
            <div className="w-full p-20 text-center">
                <div class="flex flex-wrap">
                    <div className="w-full" data-aos="zoom-in">
                        <h1 className='text-3xl md:text-5xl text-justify font-semibold'>¿Tienes otras ideas?</h1>
                        <br />
                        <h1 className="text-xl md:text-2xl text-gray text-justify">Contacta con nosotros al correo <a href="mailto:ticongle@conexion.sv" className="text-azul hover:text-sky-600 font-semibold">ticongle@conexion.sv</a><br />y nos comunicaremos contigo</h1>
                    </div>
                </div>
            </div>
            <div className="w-full p-20 bg-azul">
                <h1 className='text-center text-white text-4xl'>Sedes</h1>
                <Footer />
            </div>
        </>
    );
}

export default UneteEmpresas;