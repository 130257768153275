import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom";

import Inicio from "./views/inicio";
import UneteTicongleros from "./views/UneteTicongleros";
import BecasTicongleros from "./views/BecasTicongleros";
import Navbar from "./components/navbar";
import UneteEmpresas from "./views/UneteEmpresas";
import ContrataEmpresas from "./views/ContrataEmpresas";

import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  AOS.init();
  return (
    <>
      <Navbar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/UneteTicongleros" element={<UneteTicongleros />} />
          <Route path="/BecasTicongleros" element={<BecasTicongleros />} />
          <Route path="/UneteEmpresas" element={<UneteEmpresas />} />
          <Route path="/ContrataEmpresas" element={<ContrataEmpresas />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
