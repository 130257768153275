import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import imagenesAliados from '../images/Home/Aliados/imagenesAliados';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function CarouselAliados() {
  return (
    <div className="w-full sm:w-3/4">
      <h1 className="text-center text-3xl md:text-4xl mt-10 font-semibold">Aliados y socios estratégicos</h1>
      <Carousel swipeable={true}
        draggable={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={4000}
        transitionDuration={500}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px" >
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.isocElSalvador} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.findi} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.puma_energy} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.lacchain} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.Lacnet} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.colledge} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.BECP} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.mido} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.One} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.kanecode} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.muchasMas} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.alcaldia_puerto_de_la_libertad} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.alcaldía_ilopango} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.secretariaInnovacion} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.elanin} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.vertex} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.jobbi} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.código_facilito} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.norttech} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.fundemas} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.benowu} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.crs} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.JAElSalvador} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.tigo} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.casa_tres_mundos} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.casatic} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.creativa} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.eccsa} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.kodigo} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.SVNet} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4" src={imagenesAliados.dreamDigitalWorks} /></center>
        </div>
        <div className="h-full flex content-center items-center p-10">
          <center><img className="w-3/4 mb-7 md:mb-8 lg:mb-14 xl:mb-16" src={imagenesAliados.atiempo} /></center>
        </div>
      </Carousel>
    </div>
  );
}

export default CarouselAliados;

