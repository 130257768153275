import img1 from './1-min.png'
import img2 from './2-min.png'
import img3 from './3-min.png'
import img4 from './4-min.png'
import img5 from './5-min.png'
import img6 from './6-min.png'

export default {
    "img1": img1,
    "img2": img2,
    "img3": img3,
    "img4": img4,
    "img5": img5,
    "img6": img6
}