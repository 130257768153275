import One from "./One.png"
import kanecode from "./kanecode.png"
import muchasMas from "./muchasMas.png"
import jobbi from "./jobbi.png"
import secretariaInnovacion from "./secretariaInnovacion.png"
import elanin from "./elanin.png"
import vertex from "./vertex.png"
import código_facilito from "./código_facilito.png"
import mido from "./mido.png"
import kodigo from "./kodigo.png"
import crs from "./crs.jpg"
import JAElSalvador from "./JAElSalvador.png"
import tigo from "./tigo.png"
import casa_tres_mundos from "./casa_tres_mundos.png"
import casatic from "./casatic.png"
import creativa from "./creativa.png"
import eccsa from "./eccsa.png"
import SVNet from "./SVNet.png"
import norttech from "./norttech.png"
import fundemas from "./fundemas.png"
import dreamDigitalWorks from "./dreamDigitalWorks.png"
import atiempo from './atiempo.png'
import isocElSalvador from "./isocElSalvador.png"
import findi from "./findi.png"
import alcaldía_ilopango from "./alcaldía_ilopango.png"
import colledge from "./colledge.png"
import alcaldia_puerto_de_la_libertad from "./alcaldia_puerto_de_la_libertad.png"
import puma_energy from "./puma_energy.png"
import benowu from "./benowu.png"
import BECP from "./BECP.png"
import lacchain from "./lacchain.png"
import Lacnet from "./Lacnet.png"

export default {
    "atiempo": atiempo,
    "casa_tres_mundos": casa_tres_mundos,
    "casatic": casatic,
    "dreamDigitalWorks": dreamDigitalWorks,
    "eccsa": eccsa,
    "fundemas": fundemas,
    "jobbi": jobbi,
    "alcaldía_ilopango": alcaldía_ilopango,
    "tigo": tigo,
    "código_facilito": código_facilito,
    "isocElSalvador": isocElSalvador,
    "crs": crs,
    "JAElSalvador": JAElSalvador,
    "kanecode": kanecode,
    "kodigo": kodigo,
    "muchasMas": muchasMas,
    "One": One,
    "secretariaInnovacion": secretariaInnovacion,
    "elanin": elanin,
    "vertex": vertex,
    "mido": mido,
    "creativa": creativa,
    "findi": findi,
    "norttech": norttech,
    "colledge": colledge,
    "alcaldia_puerto_de_la_libertad": alcaldia_puerto_de_la_libertad,
    "puma_energy": puma_energy,
    "benowu": benowu,
    "BECP": BECP,
    "SVNet": SVNet,
    "Lacnet": Lacnet,
    "lacchain": lacchain
}