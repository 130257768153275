import React, { useState, Fragment } from "react";
import BannerBecasTicongleros from '../images/BecasTicongleros/BannerBecasTicongleros';
import ImgBecasTicongleros from '../images/BecasTicongleros/Pag_2/ImgBecasTicongleros';
import Numeros from '../images/BecasTicongleros/ImgNumeros/NumImg';
import Carreras from './Carreras';
import Footer from '../components/footer';
import pag2 from "../images/UneteTicongleros/Pag_2/pag2";

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function BecasTicongleros() {
    return (
        <>
            <div className="w-full text-center relative flex justify-center">
                <img src={BannerBecasTicongleros.Banner_becas} />
                <div className="absolute top-1/4 bottom-1/4 text-white">
                    <h1 className="text-lg sm:text-4xl md:text-5xl lg:text-7xl xl:text-8xl text-center">¿Listo para ser parte de <span className="font-bold">TICONGLE</span></h1>
                    <h4 className="text-xs sm:text-xl sm:mb-10 md:text-2xl lg:text-4xl xl:text-4xl mb-3">Aplica a la experiencia que cambiará tu vida</h4>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSd9qyyYIZ5_YRWR2AaE9gxORTsLmmXwFzHdddZBiixolbXESg/viewform" target="_blank" className="p-2 sm:p-3 lg:p-5 rounded-md bg-naranja text-white text-xs sm:text-xl lg:text-3xl font-semibold hover:bg-amber-600">Aplicar</a>
                </div>
            </div>
            <div className="w-full bg-white p-10">
                <h1 className="text-center text-4xl font-semibold my-10 flex justify-center"><h1 className="w-3/4">Con <span className='text-morado font-bold'>TIC</span><span className='font-bold text-azul'>ONG</span><span className='font-bold text-naranja'>LE</span> aprenderás las aptitudes que están siendo más demandadas en el mundo digital</h1></h1>
                <div class="flex flex-wrap">
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
                        <div className="rounded-3xl bg-morado p-5 flex items-center flex-wrap h-full">
                            <div className="w-2/5">
                                <img className="w-auto" src={ImgBecasTicongleros.img1} alt="" />
                            </div>
                            <div className="text-left text-white xl:text-2xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                Mayor de<br />16 años
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
                        <div className="rounded-3xl lg:bg-azul bg-morado p-5 flex items-center flex-wrap h-full">
                            <div className="w-2/5">
                                <img className="w-auto" src={ImgBecasTicongleros.img2} alt="" />
                            </div>
                            <div className="left text-white xl:text-2xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                Con o<br />sin estudios<br />universitarios
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
                        <div className="rounded-3xl sm:bg-azul bg-azul lg:bg-naranja p-5 flex items-center flex-wrap h-full">
                            <div className="w-2/5">
                                <img className="w-auto" src={ImgBecasTicongleros.img3} alt="" />
                            </div>
                            <div className="left text-white xl:text-2xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                ¿Quieres ser<br />un profesional<br />en tecnología?
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
                        <div className="rounded-3xl sm:bg-azul bg-azul lg:bg-morado p-5 flex items-center flex-wrap h-full">
                            <div className="w-2/5">
                                <img className="w-auto" src={ImgBecasTicongleros.img4} alt="" />
                            </div>
                            <div className="left text-white xl:text-2xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                ¿Estás pensando en lanzar tu propia empresa de tecnología?
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
                        <div className="rounded-3xl sm:bg-naranja bg-naranja lg:bg-azul p-5 flex items-center flex-wrap h-full">
                            <div className="w-2/5">
                                <img className="w-auto" src={ImgBecasTicongleros.img5} alt="" />
                            </div>
                            <div className="left text-white xl:text-2xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                ¿Quieres pasar del "no tengo ni idea" al "soy un programador, diseñador o estratega digital"?
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-1/2 lg:w-1/3 w-full p-5" data-aos="zoom-in">
                        <div className="rounded-3xl sm:bg-naranja bg-naranja p-5 flex items-center flex-wrap h-full">
                            <div className="w-2/5">
                                <img className="w-auto" src={ImgBecasTicongleros.img6} alt="" />
                            </div>
                            <div className="left text-white xl:text-2xl lg:text-2xl md:text-3xl sm:text-xl text-xl w-3/5">
                                ¿Quieres y tienes tiempo para practicar y después seguir practicando?
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-slate-300 p-10">
                <h1 className="text-center text-4xl font-semibold mt-5 mb-5">Proceso de admisión</h1>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div className="p-5" data-aos="zoom-in">
                        <div className="rounded-3xl p-5 text-center">
                            <center><img src={Numeros.img1} alt="" className="w-1/4" /></center>
                            <h3 className="text-center mt-5 text-slate-500 text-xl font-bold">Solicitud de beca</h3>
                            <p className="text-slate-500 text-xl">
                                Completa el formulario de la aplicación de beca
                            </p>
                        </div>
                    </div>
                    <div className="p-5" data-aos="zoom-in">
                        <div className="rounded-3xl p-5 text-center">
                            <center><img src={Numeros.img2} alt="" className="w-1/4" /></center>
                            <h3 className="text-center mt-5 text-slate-500 text-xl font-bold">Entrevista grupal</h3>
                            <p className="text-slate-500 text-xl">
                                El equipo de admisión se pondrá en contacto contigo para convocarte a una reunión grupal
                            </p>
                        </div>
                    </div>
                    <div className="p-5" data-aos="zoom-in">
                        <div className="rounded-3xl p-5 text-center">
                            <center><img src={Numeros.img3} alt="" className="w-1/4" /></center>
                            <h3 className="text-center mt-5 text-slate-500 text-xl font-bold">Taller de conocimientos</h3>
                            <p className="text-slate-500 text-xl">
                                Demostrarás que tienes las ganas y capacidad de aprendizaje
                            </p>
                        </div>
                    </div>
                    <div className="p-5" data-aos="zoom-in">
                        <div className="rounded-3xl p-5 text-center">
                            <center><img src={Numeros.img4} alt="" className="w-1/4" /></center>
                            <h3 className="text-center mt-5 text-slate-500 text-xl font-bold">Taller de actitudes</h3>
                            <p className="text-slate-500 text-xl">
                                Demostrarás tu capacidad creativa para generar ideas y soluciones que faciliten tu desempeño basado en la innovación
                            </p>
                        </div>
                    </div>
                    <div className="p-5" data-aos="zoom-in">
                        <div className="rounded-3xl p-5 text-center">
                            <center><img src={Numeros.img5} alt="" className="w-1/4" /></center>
                            <h3 className="text-center mt-5 text-slate-500 text-xl font-bold">Empieza tu carrera</h3>
                            <p className="text-slate-500 text-xl">
                                Con el proceso finalizado, estarás listo para empezar a aprender y convertirte en un Ticonglero
                            </p>
                        </div>
                    </div>
                    <div className="p-5" data-aos="zoom-in">
                        <div className="rounded-3xl p-5 text-center">
                            <center><img src={Numeros.img6} alt="" className="w-1/4" /></center>
                            <h3 className="text-center mt-5 text-slate-500 text-xl font-bold">Empieza a trabajar</h3>
                            <p className="text-slate-500 text-xl">
                                Con la certificación y carrera finalizada, estarás listo para ser un freelancer de <span className='text-morado font-bold'>TIC</span><span className='font-bold text-azul'>ONG</span><span className='font-bold text-naranja'>LE</span> trabajando en proyectos
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full p-20 bg-azul">
                <h1 className='text-center text-white text-4xl'>¿Dónde quieres estudiar?</h1>
                <Footer />
            </div>
        </>
    );
}

export default BecasTicongleros;