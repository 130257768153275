import unamonoss from './1.unamonoss-min.png'
import tu_empresa from './2.tu_empresa-min.png'
import acceso3 from './3.acceso-min.png'
import serasparte from './4.serasparte-min.png'
import acceso5 from './5.acceso-min.png'

export default {
    "unamonoss": unamonoss,
    "tu_empresa": tu_empresa,
    "acceso3": acceso3,
    "serasparte": serasparte,
    "acceso5": acceso5
}